<template>
  <div class="application-container">
    <!-- banner_about -->
    <div class="banner_application">
      <i
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_application.jpg);
        "
      ></i>
      <!-- <i style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_application_l.png);" class="l a-fadeinL"></i>
    <i style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_application_r.png);" class="r a-fadeinR"></i>-->
      <div class="margin width1480 hidden a-fdB">
        <!-- <ol>挚达未来</ol> -->
        <dd>智能应用平台</dd>
        <dl>家庭智慧能源数字化服务生态</dl>
      </div>
    </div>
    <!-- 全国家庭充电服务平台 -->
    <i class="md" id="d1"></i>
    <div id="z1">
      <div class="margin width1480 hidden application_fwpt">
        <div class="t">
          <dd>全国家庭充电服务平台</dd>
          <dl>
            <p>
              以挚达充电桩和新能源管理智能硬件（ZDbox)为入口，打通硬件+平台+APP+服务+渠道，针对电动汽车和新能源家庭使用场景，
            </p>
            <p>
              基于IOT和V2G技术构建集充电、户用光伏、V2G、储能一体化的家庭智慧能源数字化服务生态。
            </p>
          </dl>
        </div>
        <div class="f">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/application_fwpt.jpg"
          />
        </div>
      </div>
    </div>
    <!-- 挚达APP -->
    <div id="z2">
      <div class="application_app">
        <i class="md" id="d2"></i>
        <div class="margin width1480 hidden">
          <div class="t">
            <ol>
              挚达APP
            </ol>
            <dd>挚达，定制你的智能充电生活</dd>
            <dl>
              挚达APP是为电动汽车用户提供私人充电桩智能充电服务的应用，旨在带给用户在家充电场景下更多个性化、智能化体验。
            </dl>
          </div>
          <div class="f">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/%E5%A4%84%E7%90%86Test.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="margin width1480 hidden">
      <!-- application_cdkz -->
      <div id="z3">
        <div class="application_cdkz">
          <div class="l">
            <div class="c">
              <dd>充电控制</dd>
              <dl>
                <p>支持即插即充、防盗充电、预约充电等充电模式自定义设置，</p>
                <p>以及蓝牙无感离线模式。</p>
              </dl>
            </div>
          </div>
          <div class="r">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/%E5%A4%84%E7%90%86Test2.png"
            />
          </div>
        </div>
      </div>
      <!-- application_f -->
      <ul class="application_f">
        <li class="l l1" id="z4">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/application_f1.jpg"
          />
          <div class="r">
            <div class="bg">
              <dd>安全充电</dd>
              <dl>
                监测并分析用电环境安全情况，及时警告各类异常，可视化下提升安全保障。
              </dl>
            </div>
          </div>
        </li>
        <li class="r l2" id="z5">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/application_f2.jpg"
          />
          <div class="r">
            <div class="bg">
              <dd>数据统计</dd>
              <dl>
                <p>
                  清晰记录每笔充电记录，多维度解析充电数据与尖峰平谷充电时段统计，
                </p>
                <p>合理充电计划更高效合理。</p>
              </dl>
            </div>
          </div>
        </li>
        <li class="l l3" id="z6">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/application_f3.jpg"
          />
          <div class="r">
            <div class="bg">
              <dd>亲友共享</dd>
              <dl>桩主可配置“亲情账号”，轻松实现与亲友、邻居共享充电。</dl>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'

export default {
  name: 'application',
  components: {},
  setup() {
    const {proxy} = getCurrentInstance()

    onMounted(() => {
      document.title = '智能应用平台'

      // 滚动到页面最上方
      window.scrollTo(0, 0);

      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop() //滚动条距离顶端值
        for (var i = 1; i < 10; i++) {
          if ($('#z' + i) && $('#z' + i).offset()) {
            //加循环
            if ($('#z' + i).offset().top <= wst) {
              //判断滚动条位置
              $('#z' + i).addClass('on') //给当前导航加c类
            } else {
              //$("#z"+i).removeClass('on');
            }
          }
        }
      })
    })

    return {}
  },
}
</script>

<style scoped></style>
